@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use "$styles/values";

.component {
    > div:first-of-type {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr)); /* 3 equal columns */
        gap: values.$space3;
        padding: 0;
        @include mediaqueries.mediumUp {
            grid-template-columns: repeat(4, minmax(0, 1fr)); /* 4 equal columns */
            gap: 0;
        }
    }
}

.serviceWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: values.$space4;
    @include mediaqueries.mediumUp {
        margin-bottom: values.$space6;
        padding: 0 values.$space8;
    }

    .image {
        @include mediaqueries.mediumUp {
            width: 78px;
            height: 78px;
        }
    }
    .service {
        text-align: center;
        font-size: values.$px_16;
        font-weight: 500;
        line-height: 140%;
        @include mediaqueries.mediumUp {
            font-weight: 700;
            line-height: values.$px_20;
        }
    }
}