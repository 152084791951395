@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/mediaqueries';

.component {
    background-color: colors.$white;
    padding: spacings.$S spacings.$M;
}

.Title {
    @include mediaqueries.mediumUp {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 0px;
    }
    @include mediaqueries.upToMedium {
        font: fonts.$headlinesH5 !important;
      }
}

.uspWrapper {


    @include mediaqueries.mediumUp {
        display: flex;
        align-items: flex-start;
        gap: spacings.$S;
    }

}