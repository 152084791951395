@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use "$styles/values";

.component {
    a {
        display: flex;
    }
}

.title {
    text-align: center;
    @include mediaqueries.mediumUp {
        text-align: left;
    }
}
.icons {
    display: flex;
    justify-content: center;
    @include mediaqueries.mediumUp {
        justify-content: start;
    }
}
.icon {
    color: values.$colorWhite;
}
.link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: values.$colorRed500;
    &:first-child,
    &:nth-child(2) {
        margin-right: values.$space4;
    }
}