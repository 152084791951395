@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mediaqueries.smallUp {
    flex-direction: row;
  }
  .numberWrapper {
    font: values.$fontHeadlinesH5;
    color: values.$colorRed500;
    border: values.$line0-5 solid values.$colorRed500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    flex-shrink: 0;
    margin-bottom: values.$space2;
    @include mediaqueries.smallUp {
      margin-right: values.$space6;
      margin-bottom: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: values.$space6;
    @include mediaqueries.smallUp {
      align-items: baseline;
      margin-bottom: 0;
    }
    .heading {
      color: values.$colorRed500;
      font: values.$fontLabelL;
      font-weight: 600;
    }
    .text {
      margin: 0;
      text-align: center;
      @include mediaqueries.smallUp {
        text-align: start;
      }
    }
  }
}