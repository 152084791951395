@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use "$styles/variables/shadows";

.component {
  background: colors.$white;
  box-shadow: shadows.$elevationLevel1BoxShadow;
  padding: spacings.$S spacings.$M spacings.$XS;
  margin: spacings.$XS 0;
  background-color: colors.$white;
}

.Title {
  @include mediaqueries.mediumUp {
    position: absolute;
    top: 0px;
  }
  @include mediaqueries.upToMedium {
    font: fonts.$headlinesH5 !important;
  }
}

.ReviewsList {
  overflow: hidden;
  max-height: 500px;

  &.show {
    max-height: 100%;
  }

  &.hide {
    mask-image: linear-gradient(to bottom, black 25%, transparent 100%);
  }
}
