@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/utils';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/elevation';

.NavigationMenu {
  display: inline-flex;
  align-items: center;

  ul {
    @include utils.cleanList;
  }

  input[type='checkbox'] {
    display: none;
  }

  .menu-toggle {
    display: none;

    font-size: 24px;
    user-select: none;

    .StandardMenu {
      .mobileMenu,
      .closeMobileMenu {
        @include mediaqueries.smallUp {
          color: colors.$white;
        }
      }
    }

    // display hamburger icon, hide close icon
    span:last-child {
      display: none;
    }
  }
  input[type='checkbox']:checked ~ .menu-toggle {
    // hide hamburger icon, display close icon
    span:first-child {
      display: none;
    }
    span:last-child {
      display: inline-flex;
    }
  }

  // Click on hamburger enables the menu
  @include mediaqueries.upToLarge {
    .menu-toggle {
      display: inline-flex;
    }
    input[type='checkbox']:checked ~ .menu {
      display: flex;
      flex-direction: column;
    }

    .menu {
      position: absolute;
      right: 0;
      left: 0;
      top: 50px;
      bottom: 0;
      background: colors.$white;
      z-index: elevation.$top;
      height: 100vh;

      a {
        justify-content: space-between;
        width: 100%;
      }
    }
    .GarageMemberPageMenu {
      height: 50vh;
    }

    @include mediaqueries.smallUp {
      .menu {
        top: 80px;
      }
    }
  }

  .menu {
    @include typography.headlinesH5TypographyStyles;

    display: none;
    padding: 40px 20px 0 20px;
    margin: 0;

    > ul {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      @include utils.anchorColor(colors.$neutral800);
    }

    .highlight {
      @include typography.labelMTypographyStyles;

      a {
        width: auto;
      }

      // icons
      span:not(:first-child) {
        width: 24px !important;
        height: 24px !important;
        align-items: center;
        justify-content: space-around;
        background: colors.$brandPurple;
        border-radius: 20px;
        color: colors.$white;
      }
      span:last-child {
        display: none;
      }
    }

    @include mediaqueries.largeUp {
      display: block;
      padding: 0;

      @include typography.labelMTypographyStyles;
      color: colors.$white;

      ul {
        flex-direction: row;
        align-items: center;

        // arrow-right icons only mobile
        > li > a > span:not(:first-child) {
          display: none;
        }
      }

      a {
        text-decoration: none;
        @include utils.anchorColor(var(--links-color, colors.$white));
      }

      .highlight {
        a > span:first-child {
          text-decoration: underline;
        }
        // icons
        span:not(:first-child) {
          width: 32px !important;
          height: 32px !important;
        }
        span:nth-child(2) {
          display: none;
        }
        span:last-child {
          display: inline-flex;
        }
      }
    }
  }

  :global {
    @include mediaqueries.upToLarge {
      .DropDown {
        .dropdown-content {
          position: static;
          left: 0;
          border: none;
          padding: 0;

          @include typography.labelSTypographyStyles;

          ul > li {
            padding: 12px 12px 12px 0;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.GarageMemberNavMenu {
  .menu-toggle {
    .mobileMenu,
    .closeMobileMenu {
      @include mediaqueries.smallUp {
        color: colors.$neutral800;
      }
    }
  }
}
