@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
  padding: values.$space5;
  background: values.$colorWhite;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: values.$elevation4;
  transform: scale(1, 0.6666666667);
  transform-origin: top;

  @include mediaqueries.smallUp {
    padding: values.$space8 values.$space10;
    transform: scale(1, 0.6728971963);
  }

  &:global(.menuVisible) {
    transform: none;
    :global(.navBarButton),
    :global(.Img) {
      transform: none;
    }
  }

  &:global(.fx) {
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

    :global(.navBarButton) {
      transition: transform 0.3s ease-in-out;
    }
    :global(.Img) {
      transition: transform 0.3s ease-in-out;
    }
  }

  :global(.navBarButton) {
    transform: scale(1, 1.4999999999);
    
    @include mediaqueries.smallUp {
      transform: scale(1, 1.486);
    }
  }

  :global(.Img) {
    transform: scale(0.835, 1.254);

    @include mediaqueries.smallUp {
      transform: scale(1.3787878788, 2.0512);
    }
  }
  
  &:global(.top) {
    transform: scale(1, 1);
    
    :global(.navBarButton) {
      transform: scale(1, 1);
    }
    :global(.Img) {
      transform: scale(1);
      @include mediaqueries.smallUp {
        transform: scale(1.8056);
      }
    }
  }

  &:global(.top) {
    background: linear-gradient(0deg, transparent, values.$colorNeutral800 188.18%);
    box-shadow: none;
  }
}

.intersector {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 10px;
  background-color: green;
  visibility: hidden;
}