@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  column-gap: values.$space4;
}

.navbarMenuContainer {
  z-index: values.$elevation1;
}

.navbarMenuContainer {
  display: flex;
  justify-content: flex-end;
  gap: values.$space3;

  .leftButton {
    @include mediaqueries.upToSmall {
      opacity: 1;
    }
  }
}

.menuButton {
  background: black;
}

.menu {
  background: values.$purple500;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1;

  $navBarHeight: 74px;
  padding-top: calc($navBarHeight + 20px);
}

.leftButton {
  @include mediaqueries.upToSmall {
    opacity: 1;
    width: 100%;
  }
}

.menuButton {
  @include mediaqueries.upToSmall {
    gap: 0 !important;
    > span:last-child {
      font-size: 0;
      opacity: 0;
    }
  }
}

.logo {
  display: block;
  height: auto;
  transform-origin: left;
}

.siteId {
  z-index: values.$elevation1;
  will-change: filter;
}

$filterToWhite: brightness(0) invert(1);

:global(.top) {
  .siteId {
    filter: $filterToWhite;
  }

  @include mediaqueries.upToSmall {
    .leftButton {
      opacity: 0;
      width: 0;
      padding: 0;
      pointer-events: none;
    }
  
    .menuButton {
      gap: values.$space2 !important;
      > span:last-child {
        font-size: unset;
        opacity: 1;
      }
    }
  }
}
.siteId.menuOpen {
  filter: $filterToWhite;
}

:global(.fx) {
  .leftButton {
    @include mediaqueries.upToSmall {
      transition: opacity 300ms ease-in-out 600ms, width 0ms ease-in-out 600ms, padding 0ms ease-in-out 600ms;
      opacity: 1;
      width: 100%;
      overflow: hidden;
    }
  }

  .bookAppointmentButton {
    color: values.$colorWhite;
    background: values.$colorRed500;
  }
  
  .menuButton {
    @include mediaqueries.upToSmall {
      transition: gap 150ms ease-in-out 400ms;
      gap: 0 !important;
      > span:last-child {
        transition: font-size 300ms ease-in-out 300ms, opacity 300ms ease-in-out 0ms;
        font-size: 0;
        opacity: 0;
      }
    }
  }
  
  &:global(.top) {
    .leftButton {
      @include mediaqueries.upToSmall {
        transition: opacity 300ms ease-in-out 0ms, width 0ms ease-in-out 400ms, padding 0ms ease-in-out 400ms;
        opacity: 0;
        width: 0;
      }
    }

    .bookAppointmentButton {
      color: values.$colorNeutral800;
      background: values.$colorWhite;
    }
  
    .menuButton {
      @include mediaqueries.upToSmall {
        gap: values.$space2 !important;
        > span:last-child {
          transition: font-size 300ms ease-in-out 400ms, opacity 300ms ease-in-out 800ms;
          font-size: unset;
          opacity: 1;
        }
      }
    }
  }
}