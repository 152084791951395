@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.footerContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: values.$space11;
  @include mediaqueries.smallUp {
    flex-direction: row;
  }
  @include mediaqueries.smallOnly {
    align-items: end;
  }
}

.socialMediaContainer {
  display: flex;
  gap: values.$space3;
}

.socialMediaLink {
  color: values.$white;
}

.footerLinksContainer {
  display: flex;
  flex-direction: column;
  @include mediaqueries.smallUp {
    flex-direction: row;
  }
  @include mediaqueries.smallOnly {
    flex-wrap: wrap;
    margin-left: values.$space24;
  }
  @include mediaqueries.mediumUp {
    gap: values.$space8;
  }
}

.link {
  display: flex;
  gap: values.$space2;
  color: values.$white;
  font-size: values.$px_14;
  font-weight: 700;
  align-items: center;
  @include mediaqueries.upToSmall {
    margin-bottom: values.$space3;
    &:last-of-type {
      margin-bottom: values.$space6;
    }
  }
  @include mediaqueries.smallOnly {
    flex: 1 1 50%;
    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: values.$space4;
    }
  }
}
