@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';

.repairsList {
 @include mediaqueries.mediumUp {
    display: none;
 }
}

.serviceHorizontalRule {
    width: 100%;

    box-sizing: border-box;
    border: 0 none transparent;
    border-top: 1px solid colors.$neutral200;
    margin-top: spacings.$L;
    margin-bottom: spacings.$S;

    @include mediaqueries.mediumUp {
        display: none;
    }
  }

.serviceSection {
    display: flex;
    flex-direction: column;

    @include mediaqueries.largeUp {
      max-width: 50%;
    }
}

.description {
    margin-top: 0;
    margin-bottom: spacings.$XS;
    color: colors.$neutral400;
    flex-grow: 1;
    align-self: stretch;
    font: fonts.$paragraphS;
    @include mediaqueries.upToMedium {
        text-align: left;
      }
    @include mediaqueries.mediumUp {
        text-align: center;
      }
}

.headingSection {
    display: flex;
    flex-direction: row;
    gap: spacings.$XXS;
    padding: 0px;
    @include mediaqueries.mediumUp {
        flex-direction: column;
        align-items: center;
        gap: spacings.$XXXS;
        align-self: stretch;
      }
}

.ctaSection {
    display: flex;
    width: 100%;
    justify-content: center;
}

.ctaSection button {
    @include mediaqueries.upToSmall{
          width: 100%;
    }
}

.iconColor {
  color: var(--primary-color, colors.$neutral800);
}

.displayNoHr {
  display: none;
}