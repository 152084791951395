@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.businessReviewsSummary {
    padding-bottom: spacings.$XS;
}

.scoreWrapper {
    display: flex;
    gap: spacings.$XS;
    margin-bottom: -14px;
}

.stars {
    display: flex;
    gap: spacings.$XXXS;
    align-items: center;
    height: 44px;
}

.score {
  font: fonts.$headlinesH2;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.reviewsAttribution {
    display: flex;
    align-items: center;
    gap: spacings.$XXS;
}

.attributionText {
    font: fonts.$paragraphS;
    color: colors.$neutral400;
}