@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use "$styles/values";

.component {
    padding: values.$space6;
    background-color: values.$colorWhite;
    border-radius: values.$radius8;
    border: 1px solid values.$colorNeutral200;
    @include mediaqueries.mediumUp {
        padding: values.$space8;
    }
}

$defaultDisplayedContentHeightMobile: 180px;
$defaultDisplayedContentHeightDesktop: 280px;

.content {
    overflow: hidden;
    max-height: $defaultDisplayedContentHeightMobile;
    margin-bottom: values.$space6;
    font-weight: 500;
    line-height: values.$px_20;

    @include mediaqueries.mediumUp {
        max-height: $defaultDisplayedContentHeightDesktop;
    }

    &__show {
        max-height: 100%;
    }
}

.showAllToggle {
    padding-top: values.$space6;
    p {
        font-size: values.$px_14 !important;
        margin: 0;
    }
}