@use "src/styles/theme" as *; $themeName: "fixtercore";

@use '$styles/values';
@use '$styles/mediaqueries';
@use "$styles/variables/breakpoints";

.component {
    background: values.$colorNeutral100;
    padding: values.$space8 0;
}

.title {
    color: values.$colorRed500;
    margin-bottom: values.$space6 !important;
    text-align: center;
    @include mediaqueries.mediumUp {
      text-align: left;
    }
}

.allServicesLink {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
}
