@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
    border-top: 1px solid values.$colorNeutral200;
    h2 {
        font: values.$fontHeadlinesH5 !important;
    }
    :global(.slick-arrow) {
        height: values.$space8;
        width: values.$space8;
        background-color: values.$colorNeutral800;
    }
    :global(.slick-arrow.prev) {
        right: values.$space20;
        @include mediaqueries.smallUp {
            right: values.$space28;
        }
    }
    :global(.qvIcon) {
        color: values.$colorWhite;
    }
}

.googleReviewsWidget {
    display: flex;
    justify-content: center;
    padding-bottom: values.$space10;

    :global(.StarRating) {
        display: flex;
    }
}