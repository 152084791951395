@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.component {
  @include typography.paragraphXSTypography;
  font-weight: 600;
  color: values.$white;
  background: values.$red500;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: values.$space3 values.$space4;
  gap: values.$space2;
  border-radius: 100px;
  height: 32px;

  @include mediaqueries.smallUp {
    height: 43px;
  }
}

.lightTheme {
  background: values.$white;
  color: values.$neutral800;
}
