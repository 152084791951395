@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';

.GarageLocationCard {
  padding: spacings.$S spacings.$M;
  background: colors.$white;
}

.GoogleMap {
  height: 100%;
}


.LocationDetails {
  width: 100%;
  order: 2;
}
.LocationMap {
  order: 1;
  width: 100%;
  height: 100%;
}