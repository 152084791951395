@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/shadows';
@use '$styles/variables/fonts';
@use "$styles/variables/radii";

.component {
  background: colors.$white;
  box-shadow: shadows.$elevationLevel1BoxShadow;
  padding: spacings.$S spacings.$M;
  background-color: colors.$white;
  @include mediaqueries.mediumUp {
    display: flex;
    justify-content: space-between;
    gap: spacings.$S;
  }
}

.Title {
  @include  mediaqueries.mediumUp {
    color: var(--primary-color, colors.$neutral800)
  }
  @include mediaqueries.upToMedium {
    font: fonts.$headlinesH5 !important;
  }
}

.descriptionContainer {
  color: colors.$neutral600;
}

.descriptionHidden {
  @include mediaqueries.upToSmall {
    height: 100px;
    mask-image: linear-gradient(to bottom, colors.$neutral600 90%, transparent 100%);
  }
}

.showAllToggle {
  @include mediaqueries.smallUp {
    display: none;
  }
}

.TextWrapper {
  width: 100%;
}

.ImageWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @include mediaqueries.mediumUp {
    margin: 0;
  }
  @include mediaqueries.upToMedium {
    display: none;
  }
}

.Image {
  max-Height: 400px;
  border-radius: radii.$S;
  height: 100%;
  position: relative !important;
  object-fit: cover;
}