@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/values";
@use '$styles/variables/fonts';

.component {
  padding: 0 0 values.$space10 0;
  background-color: values.$colorRed500;
  @include mediaqueries.smallUp {
    padding: values.$space8 0;
  }
}

.extraHeight {
  width: 100vw;
  height: 72px;
  @include mediaqueries.smallUp {
    height: 107px;
  }
}

.backLink {
  font-weight: 700;
  padding-left: 0;
  margin-bottom: values.$space5;
  text-decoration: underline;
  @include mediaqueries.mediumUp {
    margin-bottom: values.$space11;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaqueries.mediumUp {
    align-items: start;
  }
}

.garageName {
  color: values.$colorWhite;
  line-height: values.$px_44 !important;
  margin-bottom: values.$space2 !important;
  text-align: center;
  @include mediaqueries.mediumUp {
    margin-bottom: values.$space5 !important;
    text-align: left;
  }
}
.subtitle {
  color: values.$colorWhite;
  font: fonts.$labelM;
  font-weight: 500;
  margin-bottom: values.$space5;
  text-align: center;
  @include mediaqueries.mediumUp {
    font: fonts.$labelL;
    font-weight: 600;
    text-align: left;
  }
}

.cta {
  position: relative;
  z-index: 2;
  margin-bottom: values.$space5;
  &__desktop {
    @include mediaqueries.upToMedium {
      display: none;
    }
  }
  &__mobile {
    width: 100%;
    @include mediaqueries.mediumUp {
      display: none;
    }
  }
}

.reviewSection {
  display: flex;
  align-items: center;
  color: values.$colorWhite;
  margin-bottom: values.$space5;

  &__stars img {
    margin-right: values.$space0-5;
  }

  &__googleIcon,
  &__starsWrapper,
  &__ratingNumber {
    margin-right: values.$space2;
  }
  &__ratingNumber {
    font-size: values.$px_16;
    font-weight: 700;
  }
  &__text {
    font-size: values.$px_14;
    font-weight: 500;
  }
  &__stars,
  &__ratingNumber,
  &__text {
    line-height: values.$px_20;
  }
}

.imageCol {
  @include mediaqueries.mediumUp {
    display: flex;
    justify-content: flex-end;
  }
}

.imageWrapper {
  position: relative;
  height: 200px;
  margin-bottom: values.$space5;
  z-index: 2;
  @include mediaqueries.mediumUp {
    height: 330px;
    width: 560px;
    margin-bottom: 0;
  }

  &__image {
    object-fit: cover;
    border-radius: values.$radius2;
    @include mediaqueries.mediumUp {
      border-radius: values.$radius8;
    }
  }
}

.motrioDecor {
  position: absolute;
  display: block;
  top: values.$space20;
  right: 0;
  width: 250px;
  height: 600px;
  opacity: 0.5;

  @include mediaqueries.smallUp {
    top: values.$space10;
    width: 412px;
    height: 650px;
    object-fit: cover;
    object-position: top;
  }
  @include mediaqueries.largeUp {
    height: 500px;
  }
}