@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';


@include mediaqueries.mediumUp {
  aside {
    > .sticky {
      position: sticky;
      top: spacings.$XXXL + spacings.$L;

    }
  }
}

.vrmLookupBlogDiagnostic {
  background: linear-gradient(166deg, colors.$purple800 15.54%, #2C00BA 122.1%);

  h6 {
    color: colors.$white;
  }
  p {
    color: colors.$white;
  }
}