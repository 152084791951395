@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use "$styles/values";

.row {
    z-index: 2;
    position: relative;
    padding-bottom: values.$space8;
    background-color: values.$colorNeutral100;
    @include mediaqueries.mediumUp {
        padding-top: values.$space8;
        padding-bottom: values.$space10;
    }
}

.aboutUsCol {
    margin-top: calc(-1 * values.$space10);
    @include mediaqueries.mediumUp {
        margin-top: 0;
    }

}

.aboutUs {
    :global(.content) {
        font-size: values.$px_16;
        font-weight: 500;
        line-height: 140%;
    }
}

.aboutUs,
.ourServices,
.workshopLocation {
    margin-bottom: values.$space4;
}

.workshopLocation__mobile {
    display: block;
    @include mediaqueries.mediumUp {
        display: none;
    }
}

.sharedContent {
    &__desktop {
        @include mediaqueries.upToMedium {
            display: none;
        }
    }
    &__mobile {
        @include mediaqueries.mediumUp {
            display: none;
        }
    }
}
