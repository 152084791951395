@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
  font: values.$fontLabelM;
  font-weight: 700;
  width: 100%;
  color: values.$colorWhite;
  background: transparent;
  border-radius: values.$radius8;
  border: values.$line0-5 solid values.$colorRed400;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: values.$space3 values.$space3 values.$space3 values.$space5;

  @include mediaqueries.smallUp {
    font-size: values.$px_14;
    font-weight: 500;
    line-height: values.$px_28;
  }
}

.iconWrapper {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: values.$line0-25 solid values.$colorWhite;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: values.$space4;
  flex-shrink: 0;
}