@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/radii';
@use '$styles/variables/colors';

.GoogleMap {
  height: 100%;
}

.GMap {
  width: 100%;
  height: 270px;
  border-radius: radii.$S;
  border: 1px solid colors.$neutral200;


  &.hidden {
    display: none;
  }
}

.Loader {
  height: 220px;
  position: relative;
}
