@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";
@use '$styles/tools/mixins/typography';

.blogRichText {
  padding: 0 !important;
  p {
    color: colors.$neutral400;
    @include typography.paragraphMTypography;
  }
}

.blogImage {
  width: 100%;
  height: auto;
  margin: 0 0 spacings.$L 0;
  padding: 0 !important;
  border-radius: 14px;
}

.article {
  margin-top: spacings.$XL;
}

.whyChooseFixter {
  margin: 32px 0;
  @include mediaqueries.mediumUp {
    display: none;
  }
}

.symptomNeedFurtherAssistance {
  margin: 32px 0;
}

.firstSymptomNeedFurtherAssistance {
  @include mediaqueries.mediumUp {
    display: none !important;
  }
}

.blogIntroParagraph {
  padding: 0;

  
  p {
    color: colors.$neutral600;
    @include typography.paragraphLTypography;
    @include mediaqueries.mediumUp {
      margin-top: 0;
    }
  }
}