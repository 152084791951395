@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/extends/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.modalHeading,
.modalDescription {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.modalHeading {
  @extend %headlinesH5TypographyStyles;
  margin: 0 auto;
  text-align: center;
  color: colors.$purple800;
}

.modalDescription {
  color: colors.$neutral600;
  @extend %paragraphSTypography;
  margin-bottom: 0px;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > form {
    max-width: 400px;
    margin: auto auto 0 !important;
    padding: 30px 0 0 0;

    @include mediaqueries.smallUp {
      padding: 30px 30px 0 30px;
      translate: 10px 0;
    }

    input {
      width: 280px;
      @include mediaqueries.extraSmallUp() {
        min-width: 100%;
      }
    }
  }

  .GarageLogo {
    object-fit: contain;
    margin-bottom: spacings.$L;
  }
}
.small-modal {
  .modalContainer {
    padding: 32px 20px;
  }
  .modalHeading {
    font-size: 20px;
    line-height: 24px;
    padding: 0 20px 10px;
  }
  > form {
    width: 280px;
    @include mediaqueries.smallUp {
      padding: 0;
      translate: 0;
    }
    input {
      width: 100%;
      height: 52px;
    }
  }
}
