@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';

.container {
  padding: 48px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: colors.$purple500;
  border-radius: 32px;

  h2 {
    text-align: center;
    color: colors.$white;
    font-family: Jokker;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -1.12px;

    @include mediaqueries.smallUp {
      font-size: 56px;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: -1.68px;
      margin-bottom: 128px;
    }
  }
}

.content {
  display: inherit;
  flex-direction: inherit;
  z-index: 1;
  p {
    color: colors.$neutral100;
    text-align: center;
    font-family: Jokker;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.18px;
    margin-bottom: 56px;
    max-width: 446px;
  }
  @include mediaqueries.smallUp {
    flex-direction: row;
    justify-content: space-around;
    
    p {
      text-align: left;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.44px;
    }
  }
}

.buttonGroup {
  position: relative;
  display: flex;
  flex-direction: column;

  .secondaryButtons {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .emailButton {
      margin-right: 10px;
    }
  }
}

.lines {
  position: absolute;
  z-index: -1;
  clip-path: inset(0 0 50% 0);
  transform:  translate(-56%, -45%) scale(0.5);

  @include mediaqueries.smallUp {
    transform: translate(-85%, -45%) scale(1);
  }
}