@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';

.reviewCard {
  padding-top: spacings.$S;
  margin-bottom: spacings.$M;
  border-top: 1px solid var(--colour-neutral-200, #D6D5E3);
  display: flex;
  flex-direction: column;
  gap: spacings.$XXS;
}

.reviewTitle {
  @include typography.labelMTypographyStyles;
  margin-bottom: spacings.$XXS;
}

.reviewStars {
  display: flex;
  gap: spacings.$XXXS;
}

.reviewText {
  font: fonts.$paragraphM;
  quotes: "“" "”" "‘" "’";  // css to stop rendering the quotes as << >>
}

.reviewAuthor {
  display: flex;
  gap: spacings.$XXS
}

.reviewAuthorName {
  font: fonts.$labelM
}

.reviewRelativeTime {
  font: fonts.$paragraphS;
  color: colors.$neutral400;
}