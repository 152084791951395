@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';

.GarageDetail {
    display: flex;
    margin-bottom: spacings.$S;

    .GarageDetailIcon {
      padding-top: 2px;
    }

    .GarageDetailContent {
      padding-left: spacings.$XS;

      .GarageDetailContentTitle {
        font-family: fonts.$headlinesFontFamily;
        font-weight: 600;
        padding-bottom: spacings.$XXXS;
        color:  var(--primary-color, colors.$neutral800)
      }

      .GarageDetailContentValue {
        font-family: fonts.$paragraphFontFamily;
        font-weight: 400;
      }
    }

}

.iconColor {
  color: var(--primary-color, colors.$neutral800);
}