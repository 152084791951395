@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
  height: 100%;
}

.map {
  width: 100%;
  height: 133px;
  border-radius: values.$radius4;
  @include mediaqueries.mediumUp {
    height: 220px;
  }

  &__hidden {
    display: none;
  }
}

.loader {
  height: 133px;
  position: relative;
  @include mediaqueries.mediumUp {
    height: 220px;
  }
  img {
    border-radius: values.$radius4;
  }
}
