@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.component {
  display: flex;
  flex-direction: column;
}

.backLink {
  justify-content: flex-end;
  padding-left: 0;
  margin-bottom: values.$space10;
}

.redirectLink {
  border-top: values.$line0-25 solid values.$colorRed400;
  padding-top: values.$space5;
  padding-bottom: values.$space5;
  padding-left: values.$space1;
  &:last-of-type {
    padding-bottom: values.$space20;
  }
  > a {
    color: values.$colorWhite;
    font-size: values.$px_14;
    font-weight: 700;
  }
}