@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.menuContainer {
  background: values.$colorRed500;

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 0;
  z-index: 1;

  $navBarHeight: 114px;
  padding-top: calc($navBarHeight + 20px);

  opacity: 0;
  transition: height 0s ease-in-out 400ms, opacity 0.2s ease-in-out 0s;

  .contentRow,
  .footerRow,
  .motrioDecor {
    display: none;
  }
}

.isVisible {
  opacity: 1;
  transition: height 0s ease-in-out 0s, opacity 0.2s ease-in-out 200ms;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }

  @include mediaqueries.smallUp {
    overflow: hidden;
  }

  .contentRow,
  .footerRow {
    display: grid;
  }

  .motrioDecor {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    width: 278px;
    height: 455px;
    opacity: 0.5;
    z-index: -1;

    @include mediaqueries.smallUp {
      width: auto;
      height: calc(100vh - 250px);
    }
  }
}

.col {
  &__desktop {
    @include mediaqueries.upToSmall {
      display: none;
    }
  }
}

.linkSectionTitle {
  color: values.$colorWhite;
  font-size: values.$px_20;
  line-height: values.$px_24;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: values.$space8;

  @include mediaqueries.upToSmall {
    display: none;
  }
}

.linksContainer {
  @include mediaqueries.smallUp {
    max-width: 300px;
  }
}

.sectionOneLink {
  margin-bottom: values.$space4;

  &__desktop {
    @include mediaqueries.upToSmall {
      display: none;
    }
  }
  &__mobile {
    @include mediaqueries.smallUp {
      display: none;
    }
  }
  &__forWorkshops {
    margin-top: values.$space12;
  }
}

.sectionTwoLink,
.sectionThreeLink {
  color: values.$colorWhite;
  display: block;
  font-size: values.$px_14;
  font-weight: 500;
  line-height: values.$px_28;
  padding-right: values.$space3;
}

.separator {
  border-right: values.$line0-25 solid values.$colorRed400;
}
