@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/radii';

.component {
  background: colors.$white;
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  padding-top: spacings.$M;
  justify-content: center;

  @include mediaqueries.largeUp {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include mediaqueries.largeUp {
    flex-direction: row;
    gap: 0;
  }
}

.serviceSection {
  padding-left: spacings.$S;
  padding-right: spacings.$S;
  width: 100%;

  @include mediaqueries.mediumUp {
    padding-top: spacings.$M;
    border-left: solid colors.$neutral200;
    border-width: 0 1px;
    margin-bottom: spacings.$XXXS;
  }

  &:first-child {
    @include mediaqueries.mediumUp {
      padding-left: 0;
      border-left: none;
      margin-left: spacings.$S;
    }
  }
}

.poweredBySection {
  background-color: #f5f5f5;
  border-radius: 0px 0px radii.$S radii.$S;
  margin-left: spacings.$XXXS;
  width: calc(100% - spacings.$XXS);
  justify-content: center;
  margin-bottom: spacings.$XXXS;

  @include mediaqueries.mediumUp {
    position: absolute;
    bottom: 0;
    padding-top: spacings.$XXS;
    padding-bottom: spacings.$XXS;
  }

  @include mediaqueries.upToMedium {
    display: flex;
    height: 34px;
    align-items: center;
    align-self: stretch;
    margin-top: spacings.$L;
  }
}

.GarageMembership {
  @include mediaqueries.mediumUp {
    padding-top: spacings.$M;
    padding-bottom: spacings.$XXXL;
  }
}

.GarageDirectory {
  @include mediaqueries.upToMedium {
    padding-bottom: spacings.$M;
  }
  @include mediaqueries.mediumUp {
    padding-top: spacings.$L;
    padding-bottom: spacings.$L;
  }
}
