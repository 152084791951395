@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use '$styles/values';

.carousel {
    margin: values.$space9 0;
    :global(.ArrowButton) {
        display: none;
    }
    :global(.ScrollIndicator) {
        margin-top: values.$space1;
    }

    &__cardWrapper {
        padding-left: values.$space2;
        padding-right: values.$space2;
        height: 304px;
        
        @include mediaqueries.largeUp {
            padding-left: 0;
            padding-right: 0;
            height: 350px;
        }
    }

    &__card {
        border-radius: values.$radius8;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    &__imageContainer {
        height: 100%;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 32.85%, rgba(0, 0, 0, 0.60) 57.45%);
    z-index: 1;
}

.campaign {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: values.$space8 values.$space6;
    @include mediaqueries.smallUp {
        padding: values.$space9 values.$space8;
    }
    @include mediaqueries.largeUp {
        padding: values.$space7 values.$space10;
    }

    &__content {
        height: 100%;
        display: inherit;
        flex-direction: inherit;
        justify-content: space-between;
    }

    &__label {
        width: fit-content;
        border-radius: values.$radius8;
        background: values.$colorRed500;
        padding: values.$px_2 values.$px_6;
        display: inline-block;
        font-size: values.$px_12;
        font-weight: 700;
        line-height: values.$px_14;
        text-transform: uppercase;
        color: values.$colorWhite;
        margin-bottom: values.$space2;
        @include mediaqueries.smallUp {
            margin-bottom: values.$space5;
        }
    }

    &__name {
        color: values.$colorWhite;
        font: values.$fontLabelL;
        font-weight: 600;
        line-height: values.$px_20;
        margin-bottom: values.$space1;
        text-shadow: values.$textShadow2;
        @include mediaqueries.smallUp {
            font-size: values.$px_24;
            line-height: values.$px_28;
            margin-bottom: values.$space3;
            max-width: 400px;
        }
    }

    &__offer {
        font-size: values.$px_32;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: values.$space2;
        color: values.$colorWhite;
        max-width: 600px;
        text-shadow: values.$textShadow2;
        @include mediaqueries.smallUp {
            font-size: values.$px_48;
            line-height: values.$px_36;
            margin-bottom: values.$space5;
        }
    }

    &__primaryCTA {
        height: values.$px_48 !important;
        width: fit-content;
        margin-top: values.$space6;
        @include mediaqueries.smallUp {
            height: values.$px_56 !important;
            margin-top: 0;
            position: absolute;
            right: values.$space8;
            bottom: values.$space9;
        }
        @include mediaqueries.largeUp {
            right: values.$space10;
            bottom: values.$space7;
        }
        &:hover {
            border-color: transparent;
        }
    }
    
    &__secondaryCTA {
        font-size: values.$px_12;
        font-weight: 600;
        line-height: 160%;
        text-decoration-line: underline;
        color: values.$colorWhite;
        @include mediaqueries.smallUp {
            font-size: values.$px_14;
            font-weight: 700;
        }
    }
}
