@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';


.GarageHero {
  padding-bottom: 100px !important;
}

.GaragePageContent {
  padding-top: spacings.$M;
  padding-bottom: spacings.$M;

  @include mediaqueries.upToMedium {
    padding-bottom: 0px;
    position: relative;
    margin-bottom: -60px;
    z-index: 2;

    display: flex;
    flex-direction: column;

    .CTAListColumn {
      order: 3;
    }
    .AboutUsColumn {
      order: 1;
    }
    .LocationColumn {
      order: 2;
    }
  }
}

  .GarageAboutUsCardComponent {
    @include mediaqueries.upToMedium {
      margin-top: -90px;
      margin-bottom: spacings.$XS;
      order: 1;
    }

    @include mediaqueries.mediumUp {
      box-shadow: unset !important;
      margin-bottom: spacings.$XXXL;
    }
  }


.GarageServicesCTAListComponent {
  @include mediaqueries.upToMedium {
    order: 2;
    margin-bottom: spacings.$XS;
  }

  @include mediaqueries.mediumUp {
    margin-top: -90px;
    margin-bottom: spacings.$XL;
    position: relative;
  }
}

.GarageLocation {
  @include mediaqueries.upToMedium {
    margin-bottom: spacings.$XS;
  }

  @include mediaqueries.mediumUp {
    margin-bottom: -100px !important;
    display: flex;
    gap: spacings.$XXXL;
    box-shadow: unset;
    border: 1px solid colors.$neutral200;
  }
}

.SectionGrey {

  @include mediaqueries.mediumUp {
    background-color: #F5f5f5;
  }
}

.MechanicGarageCertifications {
  @include mediaqueries.mediumUp {
    box-shadow: unset;
    margin-bottom: spacings.$XL !important;
  }
}

.CertificationsRow {
  @include mediaqueries.upToMedium {
    margin-top: 60px;
  }

  @include mediaqueries.mediumUp {
    padding-top: 130px !important;
  }
}

.GarageServiceComponent {
  margin-bottom: spacings.$XS;
}

.GoogleReviews {
  @include mediaqueries.mediumUp {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: spacings.$XXXL !important;
    gap: 157px;
    box-shadow: unset !important;
    margin-top: spacings.$XXXL !important;
  }
}

.UspsColumn {
  @include mediaqueries.mediumUp {
      padding: spacings.$XXL 0 !important;
  }
}

.MechanicGarageUspsComponent {
  margin-bottom: spacings.$XS;

  @include mediaqueries.mediumUp {
    position: relative;
    box-shadow: unset;
    background-color: #F5f5f5 !important;
    flex-direction: row !important;
    padding: 80px 0 0 0 !important;
  }
}
