@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/values';

.component {
  padding-top: values.$space11;
  padding-bottom: values.$space8;
  @include mediaqueries.smallUp {
    padding-bottom: values.$space4;
  }
  @include mediaqueries.largeUp {
    padding-bottom: values.$space14;
  }
  .title {
    color: values.$colorRed500;
    text-align: center;
    margin-bottom: values.$space9;
    @include mediaqueries.smallUp {
      margin-bottom: values.$space12;
    }
  }
}

.usp {
  margin-bottom: values.$space6;
  &:last-child {
    margin-bottom: values.$space9;
  }
}

.warrantyImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.warrantyGroup {
  display: flex;
  justify-content: space-between;
  padding: values.$space7;
  width: 100%;
  max-width: 307px;
  margin: auto;
}

.imageWrapper {
  position: relative;
  width: 111px;
  height: 160px;
  &__1 {
    margin-right: values.$space8;
  }
}