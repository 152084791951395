@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/mediaqueries';


.component {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacings.$XXS
}

.circleIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    @include mediaqueries.upToMedium {
      background: var(--primary-color-accent, white);
    }
    @include mediaqueries.mediumUp {
      background: var(--primary-color-neutral, white);
    }
}

.serviceIcon {
    position: absolute;
}

.uspLabel {
  font: fonts.$labelL;
  color: colors.$neutral800;
  margin: 0;
  text-align: center;
  font-weight: 600;
}

.uspExplanation {
  font: fonts.$paragraphS;
  color: colors.$neutral400;
  text-align: center;
  margin: 0
}

.iconColor {
  color: var(--primary-color, colors.$neutral800);
}