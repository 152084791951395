@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use '$styles/values';

.component {
    padding-top: values.$space8;
    padding-bottom: values.$space14;
}

.titleCol {
    display: flex;
    justify-content: center;
    padding-bottom: values.$space8;
    .title {
        text-align: center;
        max-width: 500px;
    }
}

.productCard {
    padding: values.$space9 values.$space10;
    border-radius: values.$radius8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 380px;
    &__1 {
        border: 1px solid values.$colorNeutral200;
        margin-bottom: values.$space5;
        @include mediaqueries.mediumUp {
            margin-bottom: values.$space6;
        }
        @include mediaqueries.largeUp {
            margin-bottom: 0;
        }
        @include mediaqueries.mediumOnly {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 200px;
        }
    }
    &__2 {
        color: values.$colorWhite;
        background-color: values.$colorRed500;
        margin-bottom: values.$space5;
        @include mediaqueries.mediumUp {
            margin-bottom: 0;
        }
    }
    &__3 {
        background-color: values.$colorNeutral100;
    }
}

.productCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @include mediaqueries.mediumOnly {
        align-items: start;
    }
}

.productCardImage {
  &__desktopMobile {
    @include mediaqueries.mediumOnly {
        display: none;
    }
  }
  &__tablet {
    display: none;
    @include mediaqueries.mediumOnly {
        display: block;
    }
  }
}

.heading {
    font-size: values.$px_20;
    font-weight: 700;
    line-height: values.$px_24;
    max-width: 220px;
}

.subHeading {
    font-size: values.$px_16;
    font-weight: 500;
    line-height: 140%;
}

.cta {
    height: values.$px_56 !important;
    border-radius: values.$radius8 !important;
    :global(.ButtonIcon) {
        height: values.$px_32 !important;
        width: values.$px_32 !important;
        margin-right: calc(-1 * values.$space2) !important;
    }
}