@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/mediaqueries";
@use "$styles/values";

.component {
    margin-bottom: values.$space9;
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        @include mediaqueries.mediumUp {
            gap: 8px;
        }
        li {
            display: flex;
            align-items: center;
            padding-top: values.$space2;
            padding-bottom: values.$space2;
            width: 100%;
            @include mediaqueries.mediumUp {
                width: calc(50% - values.$space2); /* Two columns, accounting for gap */
            }
        }
    }
}


.iconWrapper {
    flex-shrink: 0;
    height: 22px;
    width: 22px;
    background-color: values.$colorRed500;
    border-radius: 50%;
    margin-right: values.$space4;
}

.icon {
    color: values.$colorWhite;
    padding-left: 3px;
    padding-top: 3px;
}

.service {
    font-size: values.$px_16;
    font-weight: 500;
    line-height: 140%;
}