@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/elevation";
@use '$styles/mediaqueries';
@use "$styles/tools/extends/typography";

.component {
	width: 100%;
	height: 56px;

	@include mediaqueries.smallUp {
		// space for .sticky
		height: 80px;
	}

	:global {
		.VrmLookupModal-toggle {
			display: none;

			@include mediaqueries.smallUp {
				display: inline-flex;
			}
		}
	}
}

.callUsCta {
	min-width: 80px;
	a {
		padding: 0 !important;
	}
}

.toggleButton {
	display: none !important;

	@include mediaqueries.smallUp {
		display: inline-flex !important;
	}
}

:global(.transparent-nav) {
	.toggleButton {
		max-width: 200px;
		transition: 
			opacity 0.3s ease-in-out 0.7s,

			// bad performance transitions
			padding 0.3s ease-in-out 0.3s,
			max-width 0.3s ease-in-out 0.3s,
			border-width 0.3s ease-in-out 0.3s,

			// part of button's transition
			margin-right 0.4s ease-in-out;
		overflow: hidden;
	}
}

:global(.transparent-nav:not(.nav-docked)) {
	.toggleButton {
		transition: 
			opacity 0.1s ease-in-out,

			// bad performance transitions
			padding 0.1s ease-in-out 0.1s,
			max-width 0.1s ease-in-out 0.1s,
			border-width 0.1s ease-in-out 0.1s,

			// part of button's transition
			margin-right 0.4s ease-in-out;
		opacity: 0;
		max-width: 0;
		padding: 0;
		border-width: 0;
	}
}
